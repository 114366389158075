import { Link } from 'react-router-dom'
import AnimatedBG from '../../../baseComponents/AnimatedBG'
import { getUrl } from '../../../utils/routes'

import hoppiaEventsLogo from '../../../assets/svg/hoppia_events_black.svg'

const Events = props => {

  const { getLocal, lang } = props

  const onPlayPause = () => {
    var iframe = document.querySelector('iframe')
    var player = new window.Vimeo.Player(iframe)
    player.on('play', function () {
      player.setVolume(0.2)
    });
    player.getPaused().then(function (paused) {
      paused ? player.play() : player.pause()
    })
  }

  return <AnimatedBG>
    <div className='home__events' >
      <div className='home__hero_video_container site__block'>
        <div className="video_container">
          <div
            data-scroll data-scroll-speed='2'
            data-scroll-delay='0.80'
            className='home__hero_video_ video_item_ video_responsive'
            onClick={onPlayPause}>
            <iframe
              src={`https://player.vimeo.com/video/${lang === 'es' ? '635527931' : '635487458'}?autoplay=0&loop=1&autopause=0&title=0&byline=0&portrait=0&sidedock=0`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Hoppia Stage" />
          </div>
        </div>
        <div
          data-scroll data-scroll-speed='2'
          data-scroll-delay='0.10'
          className='home__hero_video_info'
        >
          <img className='site__block_logo' src={hoppiaEventsLogo} alt='hoppia Events' />
          <h4 className="font_thin bottom_space">{getLocal('hoppia_event_title_one', lang)} {getLocal('hoppia_event_title_two', lang)}</h4>
          <ul className='bottom_space'>
            <li>{getLocal('hoppia_event_list_one', lang)}</li>
          </ul>
          <div className='home__hero_video_form'>
            <Link to={getUrl('events')}>
              <span className='btn'>{getLocal('hoppia_event_btn', lang)}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </AnimatedBG>
}

export default Events