import { Route, Switch } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import Vtuber from './pages/Vtuber'
import Events from './pages/Events'
import Contact from './pages/Contact'
import SettingsProvider from './providers/Settings'

import { getUrl } from './utils/routes'

import './App.css'

const App = props => {
  return <SettingsProvider>
    <Switch>
      <Route exact path={getUrl('home')} component={Home} />
      <Route exact path={getUrl('about')} component={About} />
      <Route exact path={getUrl('events')} component={Events} />
      <Route exact path={getUrl('download')} component={Home} />
      <Route exact path={getUrl('vtuber')} component={Vtuber} />
      <Route exact path={getUrl('contact')} component={Contact} />
      <Route path={getUrl('home')} component={Home} />
    </Switch>
  </SettingsProvider>
}

export default App
