import anahuac from '../../../assets/images/about/logo_anahuac.png'
import fone from '../../../assets/images/about/logo_f1.png'
import loreal from '../../../assets/images/about/logo_loreal.png'
import unilever from '../../../assets/images/about/logo_unilever.png'
import ibero from '../../../assets/images/about/logo_ibero.png'
import cce from '../../../assets/images/about/logo_cce.png'

const Users = props => {
  const { getLocal, lang } = props

  return <div className='about__users site__block'>
    <h2 data-scroll data-scroll-speed='2'>{getLocal('users_title', lang)}</h2>
    <div className='about__users_container'>
      <img src={fone} alt='Formula 1' data-scroll data-scroll-speed='2' data-scroll-delay='0.6' />
      <img src={loreal} alt='Loreal' data-scroll data-scroll-speed='2' data-scroll-delay='0.5' />
      <img src={unilever} alt='Unilever' data-scroll data-scroll-speed='2' data-scroll-delay='0.4' />
      <img src={ibero} alt='Ibero' data-scroll data-scroll-speed='2' data-scroll-delay='0.3' />
      <img src={cce} alt='CCE' data-scroll data-scroll-speed='2' data-scroll-delay='0.2' />
      <img src={anahuac} alt='Anahuac' data-scroll data-scroll-speed='2' data-scroll-delay='0.1' />
    </div>
  </div>
}

export default Users