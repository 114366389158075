const locals = {
  'general_error': {
    'es': '* algo salió mal.',
    'en': '* something went wrong.'
  },
  'hero_subtitle': {
    'es': 'Streamings, eventos y mundos virtuales con avatares',
    'en': 'hero_subtitle'
  },
  'hero_vtuber_title_one': {
    'es': 'Streamea como un avatar 3D',
    'en': 'hero_vtuber_title_one'
  },
  'hero_vtuber_title_two': {
    'es': 'en tu escenario virtual',
    'en': 'hero_vtuber_title_two'
  },
  'hero_vtuber_list_one': {
    'es': 'Sin cámaras, luces RGB o espacio físico solo tu voz y talento',
    'en': 'hero_vtuber_list_one'
  },
  'hero_vtuber_list_two': {
    'es': 'Recibe a tu audiencia en 3D en Hoppia a transmite a Youtube, Twitch o FB',
    'en': 'hero_vtuber_list_two'
  },
  'hero_vtuber_demo_access': {
    'es': 'Accede al demo ahora',
    'en': 'hero_vtuber_demo_access'
  },
  'hero_vtuber_demo_btn': {
    'es': 'Enviar',
    'en': 'Send'
  },
  'hoppia_event_title_one': {
    'es': 'Eventos virtuales con avatares',
    'en': 'hoppia_event_title_one'
  },
  'hoppia_event_title_two': {
    'es': 'interacciones en vivo y juegos',
    'en': 'hoppia_event_title_two'
  },
  'hoppia_event_list_one': {
    'es': 'Conciertos, festivales, exposiciones, conferencias, ferias y seminarios',
    'en': 'hoppia_event_list_one'
  },
  'hoppia_event_btn': {
    'es': 'Conoce más',
    'en': 'Learn more'
  },
  'stream_question_part_one': {
    'es': '¿Quieres stremear',
    'en': 'stream_question_one'
  },
  'stream_question_part_two': {
    'es': 'como un avatar',
    'en': 'stream_question_two'
  },
  'stream_question_part_three': {
    'es': 'o hacer un evento',
    'en': 'stream_question_three'
  },
  'stream_question_part_four': {
    'es': 'en Hoppia?',
    'en': 'stream_question_four'
  },
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
