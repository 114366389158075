import Header from '../../../baseComponents/Header'
const Hero = props => {
  const { getLocal, lang } = props

  return <div className='about__hero contact__hero'>
    <Header />
    <div className='site__block home__hero_logo'>
      <h1 className='home__hero_title'>{getLocal('hero_title', lang)}</h1>
    </div>
    <div className='contact__hero_contact_cards font_medium'>
      <div className='contact__hero_card'>
        <div className='icon icon-envelope'></div>
        <a href='mailto:hello@hoppia.world?subject=Hola'>hello@hoppia.world</a>
      </div>
      <div className='contact__hero_card'>
        <div className='icon icon-celular'></div>
        <a href='mailto:hello@hoppia.world?subject=Hola'>hello@hoppia.world</a>
      </div>
      <div className='contact__hero_card full-width'>
        <div className='icon icon-pin'></div>
        <address>
          Valladolid 25, Roma Norte
          Mexico City
        </address>
      </div>
    </div>
    <div className='home__bottom_shape' />
  </div>
}

export default Hero