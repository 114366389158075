import React, { useEffect, useState, createContext } from 'react'

export const SettingsContext = createContext({ lang: 'es' })


const SettingsProvider = props => {
  const [lang, setLang] = useState('es')

  useEffect(() => {
    let currentLang = navigator.language || navigator.userLanguage
    currentLang = currentLang.search('es') !== -1 ? 'es' : 'en'
    // setLang(currentLang)
  }, [])

  return <SettingsContext.Provider value={{ lang, setLang }}>
    {props.children}
  </SettingsContext.Provider>
}

export default SettingsProvider