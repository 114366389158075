const locals = {
  'hero_title': {
    'es': 'Contacto',
    'en': 'Contact'
  },
  'stream_question_part_one': {
    'es': '¿Quieres stremear',
    'en': 'stream_question_one'
  },
  'stream_question_part_two': {
    'es': 'como un avatar',
    'en': 'stream_question_two'
  },
  'stream_question_part_three': {
    'es': 'o hacer un evento',
    'en': 'stream_question_three'
  },
  'stream_question_part_four': {
    'es': 'en Hoppia?',
    'en': 'stream_question_four'
  },
  'products_title': {
    'es': 'Nuestros productos',
    'en': 'products_title'
  },
  'products_vtuber_title': {
    'es': 'Streamea como avatar 3D en tu escenario virtual',
    'en': 'products_vtuber_title'
  },
  'products_vtuber_desc': {
    'es': 'Sin cámaras, luces RGB o espacio físico solo tu voz y talento',
    'en': 'products_vtuber_desc'
  },
  'products_events_title': {
    'es': 'Eventos virtuales con avatares, interacciones en vivo y juegos',
    'en': 'products_events_title'
  },
  'products_events_desc': {
    'es': 'Conciertos, festivales, exposiciones, conferencias, ferias y seminarios',
    'en': 'products_events_desc'
  },
  'products_btn': {
    'es': 'Conoce más',
    'en': 'Learn more'
  },
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
