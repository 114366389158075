import Header from '../../../baseComponents/Header'

import hoppiaLogo from '../../../assets/svg/hoopia_logo_white.svg'

const Hero = props => {
  const { getLocal, lang } = props

  return <div className='about__hero'>
    <Header />
    <div className='site__block home__hero_logo'>
      <h1 className='home__hero_title'>{getLocal('hero_title', lang)} <img className='logo' src={hoppiaLogo} alt='hoppia' /></h1>
      <p className="hero_title text-align-center">{getLocal('hero_subtitle', lang)}</p>
    </div>
    <div className='home__bottom_shape' />
  </div>
}

export default Hero