import React from 'react'
import AnimatedBG from '../../../baseComponents/AnimatedBG'
import InfoForm from '../../../baseComponents/InfoForm'
import Footer from '../../../baseComponents/Footer'

import bg from '../../../assets/images/home/home_bubble_bg.png'
import shapeLeft from '../../../assets/images/vtuber/hoppia_vtuber_01.png'
import shape from '../../../assets/images/vtuber/hoppia_vtuber_02.png'
import avatarLeft from '../../../assets/images/avatars/avatar_01.png'
import avatarRight from '../../../assets/images/avatars/avatar_02.png'

const News = props => {
  const { getLocal, lang } = props

  return <div className='vtuber__news'>
    <AnimatedBG>
      <div className='vtuber__news_container site__block'>
        <img
          data-scroll data-scroll-speed='2'
          data-scroll-delay='0.80'
          className='vtuber__shape_left' src={shapeLeft} alt='Hoppia' />
        <h4
          data-scroll data-scroll-speed='1'
          data-scroll-delay='0.10'
          className="font_bold"
        >{getLocal('vtuber__news_title', lang)}</h4>
        <p
          data-scroll data-scroll-speed='1'
          data-scroll-delay='0.10'
        >{getLocal('vtuber__news_paragraph', lang)}</p>
        <img
          data-scroll data-scroll-speed='2'
          data-scroll-delay='0.80'
          className='vtuber__shape' src={shape} alt='Hoppia' />
      </div>
    </AnimatedBG>
    <div className='home__stream'>
      <div className='site__block home__stream_container vtuber__stream_container'>
        <div className='home__stream_title'
          data-scroll
          data-scroll-direction='horizontal'
          data-scroll-speed='-0.4'>
          <h2 className="font_bold">{getLocal('stream_question_part_one', lang)} <br className="hidden-mobile" />
            {getLocal('stream_question_part_two', lang)} <br className="hidden-mobile" />
            {getLocal('stream_question_part_three', lang)} <br className="hidden-mobile" />
            {getLocal('stream_question_part_four', lang)}</h2>
        </div>

        <InfoForm />

        <img
          data-scroll data-scroll-speed='1'
          data-scroll-delay='0.10'
          className='home__stream_background' src={bg} alt='Fondo gris' />
      </div>
      <div className='events__news_container'>
        <img className='events__news_avatar left' src={avatarLeft} alt='Clementine' />
        <img className='events__news_avatar right' src={avatarRight} alt='Lucho' />
      </div>
      <Footer />
    </div>
  </div>
}

export default News