const locals = {
  'newsletter_title': {
    'es': 'Suscribete a nuestro newsletter',
    'en': 'newsletter_title'
  },
  'newsletter_paragraph': {
    'es': 'Regístrate para recibir noticias e información sobre streams y eventos Hoppia',
    'en': 'newsletter_paragraph'
  },
  'newsletter_btn_suscribe': {
    'es': 'Suscribirse',
    'en': 'Subscribe'
  },
  'newsletter_success_text': {
    'es': 'Gracias',
    'en': 'newsletter_success_text'
  },
  'newsletter_terms': {
    'es': 'Términos y Condiciones / Aviso de Privacidad',
    'en': 'newsletter_terms'
  },
  'contact_us': { 'es': 'Contáctanos', 'en': 'Contact us' }
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
