import React, { useState } from 'react'
import { validateEmail } from '../../../utils/inputValidations'
import axios from 'axios'
import Header from '../../../baseComponents/Header'
import Loader from '../../../baseComponents/Loader'

import hoppiaLogo from '../../../assets/svg/hoopia_logo_white.svg'
import hoppiaVtuberLogo from '../../../assets/svg/hoppia_vtuber_logo.svg'

const Hero = props => {
  const [email, setEmail] = useState('')
    , [errors, setErrors] = useState({})
    , [isSending, setIsSending] = useState(false)
    , [success, setSuccess] = useState(false)

  const { getLocal, lang } = props

  const onPlayPause = () => {
    var iframe = document.querySelector('iframe')
    var player = new window.Vimeo.Player(iframe)
    player.on('play', function () {
      player.setVolume(0.2)
    });
    player.getPaused().then(function (paused) {
      paused ? player.play() : player.pause()
    })
  }

  const onSendForm = () => {
    setErrors({})
    let warnings = validateEmail(email)
    if (warnings) return setErrors(warnings)
    setIsSending(true)
    axios({
      url: 'https://hoppia.herokuapp.com/early_access',
      method: 'POST',
      data: { email }
    })
      .then(res => {
        if (res.data.error) setErrors(res.data.error)
        if (res.data.success) setSuccess(true)
        setIsSending(false)
        setSuccess(true)
      })
      .catch(err => {
        setIsSending(false)
        setErrors({ general: getLocal('general_error', lang) })
      })
  }

  return <div className='home__hero'>
    <Header />
    <div className='site__block home__hero_logo'>
      <img className="hero_image_title" src={hoppiaLogo} alt='hoppia' />
      <h4 className="hero_title text-align-center">{getLocal('hero_subtitle', lang)}</h4>
    </div>
    <div className='site__block home__hero_video_container'>
      <div className="video_container">
        <div
          data-scroll data-scroll-speed='2'
          data-scroll-delay='0.80'
          className='home__hero_video_ video_item_ video_responsive'
          onClick={onPlayPause}>
          <iframe
            src="https://player.vimeo.com/video/598639289?autoplay=0&loop=1&autopause=0&title=0&byline=0&portrait=0&sidedock=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Hoppia Stage" />
        </div>
      </div>
      <div className='site__block home__hero_video_info' data-scroll data-scroll-speed='2' data-scroll-delay='0.10'>
        <img className='site__block_logo' src={hoppiaVtuberLogo} alt='hoppia Vtuber' />
        <h4 className="font_thin bottom_space">{getLocal('hero_vtuber_title_one', lang)} {getLocal('hero_vtuber_title_two', lang)}</h4>
        <ul className='bottom_space'>
          <li>{getLocal('hero_vtuber_list_one', lang)}</li>
          <li>{getLocal('hero_vtuber_list_two', lang)}</li>
        </ul>
        <h3 className='home__hero_video_form_title font_bold'>{getLocal('hero_vtuber_demo_access', lang)}</h3>
        <div className='home__hero_video_form'>
          <input
            type='email'
            onChange={e => setEmail(e.target.value)}
            value={email}
            className='text_input'
            placeholder='Email address' />
          {
            isSending
              ? <span className='btn'><Loader type='circle' /></span>
              : <span onClick={onSendForm} className='btn'>{getLocal('hero_vtuber_demo_btn', lang)}</span>
          }
        </div>
        <span className='error'>{errors.email}</span>
        {
          success &&
          <span style={{ marginTop: '1vmin', display: 'inline-block' }}>Gracias</span>
        }
      </div>
    </div>
    <div className='home__bottom_shape' />
  </div>
}

export default Hero