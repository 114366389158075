import React, { useContext } from 'react'
import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'
import { Link } from 'react-router-dom'
import { getUrl } from '../../utils/routes'

import hoppiaLogo from '../../assets/svg/hoopia_imagotipo.svg'

import './index.css'

const Header = props => {
  const context = useContext(SettingsContext)
    , { lang } = context
  return <div id='header' className="font_semi">
    <header className='header site__block'>
      <img className='header__isotype' src={hoppiaLogo} alt='hoppia' />
      <nav className='header__nav'>
        <ul>
          <li className='header__nav_item'>
            <Link to={getUrl('home')}>{getLocal('home', lang)}</Link>
          </li>
          <li className='header__nav_item'>
            <Link to={getUrl('vtuber')}>Hoppia Vtuber</Link>
          </li>
          <li className='header__nav_item'>
            <Link to={getUrl('events')}>Hoppia Events</Link>
          </li>
          <li className='header__nav_item'>
            <Link to={getUrl('about')}>{getLocal('about', lang)}</Link>
          </li>
        </ul>
      </nav>
      <Link to={getUrl('contact')} className='btn'>{getLocal('contact_us', lang)}</Link>
    </header>
    <nav className='header__nav_mobile'>
      <ul>
        <li className='header__nav_item'>
          <Link to={getUrl('home')}>{getLocal('home', lang)}</Link>
        </li>
        <li className='header__nav_item'>
          <Link to={getUrl('vtuber')}>Hoppia Vtuber</Link>
        </li>
        <li className='header__nav_item'>
          <Link to={getUrl('events')}>Hoppia Events</Link>
        </li>
        <li className='header__nav_item'>
          <Link to={getUrl('about')}>{getLocal('about', lang)}</Link>
        </li>
      </ul>
    </nav>
  </div>
}

export default Header
