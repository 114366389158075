import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


const SlideBtn = ({ className, style, onClick }) => {
  return <div
    className={`slide-btn ${className}`}
    style={{ ...style, }}
    onClick={onClick}
  />
}

const NewsSlider = ({ news }) => {

  const settings = {
    infinite: news.length > 2,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlideBtn />,
    prevArrow: <SlideBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return <Slider {...settings}>
    {
      news.map((newsInfo, index) => <a key={index} href={newsInfo.URL} target='_blank' rel='noopener noreferrer' className='newsSlider_item'>
        <img src={newsInfo.PosterURL} alt={newsInfo.Title} />
        <h5>{newsInfo.Title}</h5>
        {console.log(newsInfo)}
      </a>)
    }
  </Slider>
}

export default NewsSlider