const VideoInfo = props => {
  const onPlayPause = () => {
    var iframe = document.querySelector('iframe')
    var player = new window.Vimeo.Player(iframe)
    player.on('play', function () {
      player.setVolume(0.2)
    });
    player.getPaused().then(function (paused) {
      paused ? player.play() : player.pause()
    })
  }

  // return <div className='vtuber_video_info site__block'>
  //   <div
  //     data-scroll data-scroll-speed='1'
  //     data-scroll-delay='0.40'
  //     className='home__hero_video video_item'
  //     onClick={onPlayPause}>
  //     <iframe
  //       src="https://player.vimeo.com/video/598639289?autoplay=0&loop=1&autopause=0&title=0&byline=0&portrait=0&sidedock=0"
  //       frameBorder="0"
  //       allow="autoplay; fullscreen; picture-in-picture"
  //       allowFullScreen
  //       title="Hoppia Stage" />
  //   </div>
  // </div>

  return <div className='site__block video_margin_top_negative video_block'>
    <div className="video_container">
      <div
        data-scroll data-scroll-speed='1'
        data-scroll-delay='0.40'
        className='video_responsive '
        onClick={onPlayPause}>
        <iframe
          src="https://player.vimeo.com/video/598639289?autoplay=0&loop=1&autopause=0&title=0&byline=0&portrait=0&sidedock=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Hoppia Stage" />
      </div>
    </div>
  </div>
}

export default VideoInfo