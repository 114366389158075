import React, { useEffect } from 'react'
import locomotiveScroll from 'locomotive-scroll'

const ScrollContainer = props => {
  const scrollRef = React.createRef()

  useEffect(() => {
    let scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true
    })
    setTimeout(() => {
      if (scroll && scroll.destroy) scroll.destroy()
      scroll = new locomotiveScroll({
        el: scrollRef.current,
        smooth: true
      })
    }, 1000)
    /* scroll.on('scroll', args => {
      let scrollLimit = args.limit.y
      let scrollPosition = args.delta.y
      let scrollPercent = (scrollPosition * 100) / scrollLimit
      //scrollPercent = Math.abs(100 - scrollPercent)

      let delta = parseInt(args.delta.y / 10) - 100
      if (delta < 100) delta = 100
      let images = document.getElementsByClassName('parallax__image')
      images = Array.from(images)
      images.forEach(image => {
        image.style.top = `-${scrollPercent * 5}px`
      })
    }) */
    return () => {
      if (scroll && scroll.destroy) scroll.destroy()
    }
  })

  return <div className='data_scroll_container' {...props} ref={scrollRef} data-scroll-container>
    {props.children}
  </div>
}

export default ScrollContainer