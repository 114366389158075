import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../utils/routes'
import { validateEmail } from '../../utils/inputValidations'
import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'
import axios from 'axios'
import AnimatedBG from '../AnimatedBG'
import Loader from '../Loader'

import './index.css'

const Footer = props => {
  const [email, setEmail] = useState('')
    , [errors, setErrors] = useState({})
    , [isSending, setIsSending] = useState(false)
    , [success, setSuccess] = useState(false)

  const context = useContext(SettingsContext)
    , { lang } = context

  const onSendForm = () => {
    setErrors({})
    let warnings = validateEmail(email)
    if (warnings) return setErrors(warnings)
    setIsSending(true)
    axios({
      url: 'https://hoppia.herokuapp.com/newsletter',
      method: 'POST',
      data: { email }
    })
      .then(res => {
        if (res.data.error) setErrors(res.data.error)
        if (res.data.success) setSuccess(true)
        setIsSending(false)
        setSuccess(true)
      })
      .catch(err => {
        setIsSending(false)
        setErrors({ general: '* algo salió mal.' })
      })
  }

  return <div className='footer__background footer site__block'>
    <div className='footer_info'>
      <div className='footer_info_suscription'>
        <h3 className="font_bold">{getLocal('newsletter_title', lang)}</h3>
        <p>{getLocal('newsletter_paragraph', lang)}</p>
        <div className='home__hero_video_form'>
          <input
            type='email'
            onChange={e => setEmail(e.target.value)}
            value={email}
            className='text_input'
            placeholder='Email address' />
          {
            isSending
              ? <span className='btn'><Loader type='circle' /></span>
              : <span onClick={onSendForm} className='btn'>{getLocal('newsletter_btn_suscribe', lang)}</span>
          }
        </div>
        {
          success &&
          <p>{getLocal('newsletter_success_text', lang)}</p>
        }
        <p className='error'>{errors.general}</p>
      </div>
      <div className='footer_info_contact'>
        <div className='footer_address'>
          <h3 className="font_bold">{getLocal('contact_us', lang)}</h3>
          <p>+52 55 5739 6061</p>
          <p>Valladolid 25, Roma Norte,</p>
          <p>CDMX México</p>
        </div>
      </div>
      <div className='social_networks'>
        <a href='https://www.facebook.com/hoppia.world/' rel='noopener noreferrer' target='_blank'>
          <div className='icon icon-facebook' />
        </a>
        <a href='https://twitter.com/hoppiaworld?lang=en' rel='noopener noreferrer' target='_blank'>
          <div className='icon icon-twitter' />
        </a>
        <a href='https://www.instagram.com/hoppia.world' rel='noopener noreferrer' target='_blank'>
          <div className='icon icon-instagram' />
        </a>
      </div>
    </div>
    <div className='footer_legal'>
      <Link to={getUrl('legals')}>{getLocal('newsletter_terms', lang)}</Link>
      <span>©Hoppia 2021</span>
    </div>
  </div>
}

export default Footer