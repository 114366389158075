const routes = {
  about: { url: '/acerca' },
  events: { url: '/eventos' },
  contact: { url: '/contacto' },
  download: { url: '/descarga' },
  home: { url: '/' },
  vtuber: { url: '/vTuber' },
  legals: { url: '/legales' }
}

export const getUrl = (name) => (routes[name] ? routes[name].url : '/')
