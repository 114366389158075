import React, { useContext, useState } from 'react'
import axios from 'axios'
import { validateInfoForm } from '../../utils/inputValidations'
import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'
import Loader from '../Loader'

const InfoForm = props => {
  const [name, setName] = useState('')
    , [email, setEmail] = useState('')
    , [phone, setPhone] = useState('')
    , [productType, setProductType] = useState('Hoppia Events')
    , [errors, setErrors] = useState({})
    , [isSending, setIsSending] = useState(false)
    , [success, setSuccess] = useState(false)

  const context = useContext(SettingsContext)
    , { lang } = context

  const onSendForm = () => {
    setErrors({})
    let warnings = validateInfoForm({
      name, email, phone, lang
    })
    if (warnings) return setErrors(warnings)
    setIsSending(true)
    axios({
      url: 'https://hoppia.herokuapp.com/hoppia_info',
      method: 'POST',
      data: { email, phone, productType, name }
    })
      .then(res => {
        if (res.data.error) setErrors(res.data.error)
        if (res.data.success) setSuccess(true)
        setIsSending(false)
        setSuccess(true)
      })
      .catch(err => {
        setIsSending(false)
        setErrors({ general: getLocal('general_error', lang) })
      })
  }


  if (success) return <div className='home__stream_form'>
    <h2 className='text_align_center'>{getLocal('success_message_one', lang)}</h2>
    <h2 className='text_align_center'>{getLocal('success_message_two', lang)}</h2>
  </div>

  return <div className='home__stream_form'
    data-scroll data-scroll-speed='1'>
    <div className='home__stream_input'>
      <label>{getLocal('form_name_label', lang)} <span className='error'>{errors.name}</span></label>
      <input
        onChange={e => setName(e.target.value)}
        value={name}
        className='text_input' />
    </div>
    <div className='home__stream_input'>
      <label>{getLocal('form_email_label', lang)} <span className='error'>{errors.email}</span></label>
      <input
        type='email'
        onChange={e => setEmail(e.target.value)}
        value={email}
        className='text_input' />
    </div>
    <div className='home__stream_input'>
      <label>{getLocal('form_phone_label', lang)} <span className='error'>{errors.phone}</span></label>
      <input
        type='tel'
        onChange={e => setPhone(e.target.value)}
        value={phone}
        className='text_input' />
    </div>
    <div className='home__stream_input'>
      <label>{getLocal('form_info_label', lang)} <span className='error'>{errors.productType}</span></label>
      <select
        onChange={e => setProductType(e.target.value)}
        value={productType}
        className='text_input'>
        <option value='Hoppia Events'>Hoppia Events</option>
        <option value='Hoppia Vtuber'>Hoppia Vtuber</option>
      </select>
    </div>
    {
      isSending
        ? <span className='btn'><Loader /></span>
        : <span className='btn' onClick={onSendForm}>{getLocal('form_btn_send', lang)}</span>
    }

    <div>
      <span className='error'>{errors.general}</span>
    </div>
  </div>
}

export default InfoForm