const locals = {
  'hero_subtitle': {
    'es': 'Stremea como avatar 3D en tu escenario virtual',
    'en': 'hero_subtitle'
  },
  'vtuber__news_title': {
    'es': 'Sin cámaras, luces RGB o espacio físico solo tu voz y talento',
    'en': 'vtuber__news_title'
  },
  'vtuber__news_paragraph': {
    'es': 'Recibe a tu audiencia en 3D en Hoppia o transmite a Youtube, Twitch o FB',
    'en': 'vtuber__news_paragraph'
  },
  'stream_question_part_one': {
    'es': '¿Quieres acceder',
    'en': 'stream_question_one'
  },
  'stream_question_part_two': {
    'es': 'al demo de Hoppia',
    'en': 'stream_question_two'
  },
  'stream_question_part_three': {
    'es': 'Vtuber y probarlo',
    'en': 'stream_question_three'
  },
  'stream_question_part_four': {
    'es': 'ahora?',
    'en': 'stream_question_four'
  },
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
